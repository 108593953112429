import {Component, Input} from '@angular/core';
import {GenericEDIComponent} from '../../GenericEDIComponent';
import {EDIGJsonLoop} from '../../../../models/EDIGJson';

@Component({
  selector: 'app-ctp-loop',
  templateUrl: './ctp-loop.component.html'
})
export class CtpLoopComponent extends GenericEDIComponent {

    @Input() x12V: string;
    @Input() currencyCode: string;
    @Input() ctpLoop: EDIGJsonLoop;
}
