import {Component, Inject} from '@angular/core';
import {FormUpdateError} from '../../shared/services/EgressDocService';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-form-error-summary-dialog',
  templateUrl: './form-error-summary-dialog.component.html'
})
export class FormErrorSummaryDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: {
                errors?: Array<FormUpdateError>,
                navigate: (error: FormUpdateError) => void },
                public dialogRef: MatDialogRef<FormErrorSummaryDialogComponent>) {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  navigateToError(error: FormUpdateError) {
    this.closeDialog();
    this.data.navigate(error);
  }
}
