import {Component, Input} from '@angular/core';
import {GenericEDIComponent} from '../../GenericEDIComponent';
import {EDIGJsonSingleSegment} from '../../../../models/EDIGJson';
import {EDIConstants} from '../../../../shared/constant/EDIConstants';
import {InvoiceConstants} from '../../../../shared/constant/InvoiceConstants';

@Component({
    selector: 'app-po1-seg',
    templateUrl: './po1-seg.component.html',
    styleUrls: ['../../generic-edi.component.css', '../../x12-edi850/x12-edi850.component.css']
})
export class Po1SegComponent extends GenericEDIComponent {

    @Input() po1: EDIGJsonSingleSegment;

    getPSIDKeys(po1) {
        const ret = [];
        for (let i = 6; i < 25; i = i + 2) {
            if (this.existsElement(po1, '.', i)) {
                ret.push(i);
            }
        }
        return ret;
    }

    getPSIDValues(po1) {
        const ret = [];
        for (let i = 6; i < 25; i = i + 2) {
            if (this.existsElement(po1, '.', i)) {
                ret.push(i + 1);
            }
        }
        return ret;
    }

    readonly unitPriceTypeCodes = EDIConstants.getMappingAsArray(InvoiceConstants.unitPriceBases);
    readonly unitTypes = EDIConstants.getMappingAsArray(InvoiceConstants.unitTypes);
    readonly psCodes = EDIConstants.getMappingAsArray(EDIConstants.productIDQualifiers);
}
