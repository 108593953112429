import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IAdditionalCharge} from '../../../models/ITenant';

@Component({
  selector: 'app-additional-payment',
  templateUrl: './additional-payment.component.html',
  styleUrl: './additional-payment.component.scss'
})
export class AdditionalPaymentComponent implements OnInit {

  displayedColumns = ['eventTime', 'amount', 'status', 'receiptUrl'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: IAdditionalCharge[],
              public dialogRef: MatDialogRef<AdditionalPaymentComponent>) {
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  getColorByStatus(status: string) {
    switch (status) {
      case 'active':
        return 'primary';
      case 'past_due':
        return 'warn';
      default:
        return 'accent';
    }
  }

  getStatusName(status: string) {
    switch (status) {
      case 'active':
        return 'SUCCESS';
      case 'past_due':
        return 'FAILED';
      default:
        return status.toUpperCase();
    }
  }

}
