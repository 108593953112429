// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.container {
  margin: 0 5px;
  width: 700px;
  padding: 7px;
}

h2 {
  display: inline;
}`, "",{"version":3,"sources":["webpack://./src/app/core/subscription/additional-payment/additional-payment.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":["div.container {\n  margin: 0 5px;\n  width: 700px;\n  padding: 7px;\n}\n\nh2 {\n  display: inline;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
