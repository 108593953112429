import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';

import {OrgAdminGuard} from './org-admin.guard';
import {BaseAuthGuard} from './base-auth.guard';

import {AuthService} from '../services/auth.service';
import {DialogService} from '../services/dialog.service';
import {AuthGuard} from './auth.guard';
import {OrgAdminOrSALoggedAsGuard} from './org-admin-or-sa-logged-as.guard';
import {GenericRoleBasedGuard} from './generic-role-based.guard';
import {RegularGuard} from './regular.guard';
import {ManagementGuard} from './management.guard';

export const GUARDS = {
    OrgAdminGuard: 'OrgAdminGuard',
    BaseAuthGuard: 'BaseAuthGuard',
    AuthGuard: 'AuthGuard',
    OrgAdminOrSALoggedAsGuard: 'OrgAdminOrSALoggedAsGuard',
    RegularGuard: 'RegularGuard',
    ManagementGuard: 'ManagementGuard'
};

@Injectable({
    providedIn: 'root'
})
export class MasterGuard implements CanActivate {

    constructor(protected authService: AuthService, protected dialogService: DialogService, protected router: Router) {}

    private route: ActivatedRouteSnapshot;
    private state: RouterStateSnapshot;

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {

        this.route = route;
        this.state = state;

        return (!route.data?.guards?.length) ? true : this.executeGuards(route.data.guards);
    }

    private async executeGuards(guardNames: string[], guardIndex: number = 0) {
        try {
            const activateRoute = await this.activateGuard(guardNames[guardIndex]);

            if (activateRoute && guardIndex < guardNames.length - 1) {
                return this.executeGuards(guardNames, guardIndex + 1);
            } else {
                return activateRoute;
            }
        } catch (e) {
            return Promise.reject(false);
        }
    }

    private activateGuard(guardKey: string): Promise<boolean> {

        let guard: GenericRoleBasedGuard | BaseAuthGuard;

        switch (guardKey) {
            case GUARDS.OrgAdminGuard:
                guard = new OrgAdminGuard(this.authService, this.dialogService);
                break;
            case GUARDS.BaseAuthGuard:
                guard = new BaseAuthGuard(this.authService, this.router);
                break;
            case GUARDS.AuthGuard:
                guard = new AuthGuard(this.authService, this.router);
                break;
            case GUARDS.OrgAdminOrSALoggedAsGuard:
                guard = new OrgAdminOrSALoggedAsGuard(this.authService, this.dialogService);
                break;
            case GUARDS.RegularGuard:
                guard = new RegularGuard(this.authService, this.dialogService);
                break;
            case GUARDS.ManagementGuard:
                guard = new ManagementGuard(this.authService, this.dialogService);
                break;
        }
        return guard.canActivate(this.route, this.state);
    }
}
