import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'combineFields',
  standalone: true
})
export class CombineFieldsPipe implements PipeTransform {

  transform(data: Record<string, any[][]>, seg: string, index: number, separator: string): string {
    if (!data || !seg || !data[seg] || !Array.isArray(data[seg])) {
      return '';
    }
    return data[seg].map((item: any[]) => item[index] ?? '').join(separator);
  }
}
