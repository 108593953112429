import {Component, Input} from '@angular/core';
import {GenericEDIComponent} from '../../GenericEDIComponent';
import {EDIGJsonLoop} from '../../../../models/EDIGJson';
import {EDIConstants} from '../../../../shared/constant/EDIConstants';
import {POConstants} from '../../../../shared/constant/POConstants';
import {InvoiceConstants} from '../../../../shared/constant/InvoiceConstants';
import {POAckConstants} from '../../../../shared/constant/POAckConstants';

@Component({
  selector: 'app-po1-loop',
  templateUrl: './po1-loop.component.html',
  styleUrls: ['../../generic-edi.component.css', '../../x12-edi850/x12-edi850.component.css']
})
export class Po1LoopComponent extends GenericEDIComponent {

  @Input() x12V: string;
  @Input() po1Loop: EDIGJsonLoop;
  @Input() currencyCode: string;

  readonly qtyTypes = EDIConstants.getMappingAsArray(POConstants.quantityTypes673);
  readonly unitTypes = EDIConstants.getMappingAsArray(InvoiceConstants.unitTypes);
  readonly ackStatus = EDIConstants.getMappingAsArray(POAckConstants.ackStatus);
  readonly dateTypes = EDIConstants.getMappingAsArray(POAckConstants.dateTypes);
}
